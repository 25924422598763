import { useState, useEffect } from 'react';

// This hook is used to determine if we should continue to render the server placeholder
// during hydration on the first render to prevent mismatch. If mismatch occurs, hydration
// can break.
export const useRenderServerPlaceholder = () => {
	const isTransition = window.__SSR_RENDERED__ === false;
	const [renderServerPlaceholder, setRenderServerPlaceholder] = useState(
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		((window as any)?.__HYDRATABLE__ && !isTransition) || Boolean(process.env.REACT_SSR),
	);

	useEffect(() => {
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (!process.env.REACT_SSR) {
			setRenderServerPlaceholder(false);
		}
	}, []);

	return renderServerPlaceholder;
};
